import React from 'react';
import constants from '../../utils/constants';

export default () => {
    return (
        <>
            <section id="map">
                <div>
                    <iframe
                        src={constants.MAP_IFRAME_LINK}
                        width="100%"
                        height="500"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0">
                    </iframe>
                </div>
            </section>
        </>
    )
}