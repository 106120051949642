import React, {useReducer} from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import {Button, Col, Container, Form} from 'react-bootstrap';
import {notification, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import 'moment/locale/bg';


const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

const initState = {
    isLoading: false,

    names: null,
    namesIsValid: null,

    email: null,
    emailIsValid: null,

    subject: null,
    subjectIsValid: null,

    message: null,
    messageIsValid: null,

    phone: null,
    phoneIsValid: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'value.change':
            return {
                ...state,
                [action.name]: action.value
            }

        case 'submit':
            return {
                ...state,
                isLoading: true,
            }

        case 'stop':
            return {
                ...state,
                isLoading: false,
            }
        case 'reset':
            return {
                ...initState
            }
        default:
            return state;
    }
}


const Wrapper = styled.div`
`;

const Reservation = () => {
    const [state, dispatch] = useReducer(reducer, initState);

    const validateInputs = () => {

        const invalidFields = [];


        if (state.names == null) {
            invalidFields.push('Имена');
            dispatch({type: "value.change", name: 'namesIsValid', value: true});
        }
        if (state.email == null || !(state.email.includes('.') && state.email.includes('@'))) {
            invalidFields.push('Мейл');
            dispatch({type: "value.change", name: 'emailIsValid', value: true});
        }
        if (state.subject == null || state.subject.length < 1) {
            invalidFields.push('Тема');
            dispatch({type: "value.change", name: 'subjectIsValid', value: true});
        }
        if (state.message == null || state.message.length < 1) {
            invalidFields.push('Съобщение');
            dispatch({type: "value.change", name: 'messageIsValid', value: true});
        }

        if (state.phone == null || state.phone.length < 6) {
            invalidFields.push('Телефон');
            dispatch({type: "value.change", name: 'phoneIsValid', value: true});
        }


        if (invalidFields.length > 0) {
            // SEND notification


            notification.open({
                message: "Неуспешно изпращане",
                description: `Следните полета са задължителни: ${invalidFields.join(', ')}`,
                className: 'error-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            })
            dispatch({type: 'stop'});
            return;
        }
        // SEND EMAIL here

        const templateData = {
            reply_to: state.email,
            message: state.message,
            subject: state.subject,
            from_name: state.names,
            phone: state.phone
        };

        emailjs.send("gmail", "main_template", templateData, "user_bR51gMfZaFNKFx4XQ4f5T").then((function (e) {

            notification.open({
                message: "Успешно изпращане",
                description: `Очаквайте скоро да се свържем с Вас!`,
                className: 'success-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            });

            dispatch({type: 'reset'});

        }), (function (e) {
            console.log("🚀 ~ file: reservation.js ~ line 135 ~ e", e)
            notification.open({
                message: "Неуспешно изпращане",
                description: `Моля опитайте отново!`,
                className: 'error-notification',
                duration: 5,

                style: {
                    width: 450,
                }
            })
            dispatch({type: 'stop'});

        }));
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        dispatch({type: 'submit'});
        validateInputs();
    }

    return (
        <>
            <Wrapper className="mb-5">
                <Container className="inner-container">
                    <div className="text-left my-4">
                        <h3 className="">Контактна форма</h3>
                        <div className='reservations-form text-left'>
                            <Form onSubmit={handleSubmitForm}>
                                <Form.Row>
                                    <Form.Group as={Col} xs={12} controlId="formGridAddress1">
                                        <Form.Label>Имена:</Form.Label>
                                        <Form.Control
                                            value={state.names}
                                            placeholder="Въведи имена"
                                            className="customInput"
                                            isInvalid={state.namesIsValid}
                                            onChange={({target}) => {
                                                dispatch({type: "value.change", name: 'names', value: target.value})
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} controlId="formGridCity">
                                        <Form.Label>Мейл:</Form.Label>
                                        <Form.Control
                                            value={state.email}
                                            placeholder="Въведи мейл"
                                            className="customInput"
                                            isInvalid={state.emailIsValid}
                                            onChange={({target}) => {
                                                dispatch({type: "value.change", name: 'email', value: target.value})
                                            }}
                                        />
                                    </Form.Group>


                                    <Form.Group as={Col} xs={12} controlId="formGridCity">
                                        <Form.Label>Телефон:</Form.Label>
                                        <Form.Control
                                            value={state.phone}
                                            placeholder="Въведи телефон"
                                            className="customInput"
                                            isInvalid={state.phoneIsValid}
                                            onChange={({target}) => {
                                                dispatch({type: "value.change", name: 'phone', value: target.value})
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} controlId="formGridCity">
                                        <Form.Label>Тема:</Form.Label>
                                        <Form.Control
                                            value={state.subject}
                                            placeholder="Въведи тема на съобщението"
                                            className="customInput"
                                            isInvalid={state.subjectIsValid}
                                            onChange={({target}) => {
                                                dispatch({type: "value.change", name: 'subject', value: target.value})
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Съобщение:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={state.message}
                                            placeholder='Вашето запитване'
                                            className="customInput"
                                            isInvalid={state.subjectIsValid}
                                            onChange={({target}) => {
                                                dispatch({type: "value.change", name: 'message', value: target.value})
                                            }}
                                        />
                                    </Form.Group>

                                </Form.Row>

                                <div className="text-left mt-3">
                                    {!state.isLoading ?
                                        (
                                            <>
                                                <Button className="btn btn-green" type="submit">
                                                    Изпрати запитване
                                                </Button>
                                            </>
                                        ) : (
                                            <Spin indicator={antIcon}/>
                                        )}

                                </div>
                            </Form>
                        </div>
                    </div>
                </Container>
            </Wrapper>
        </>
    )
}

export default Reservation;